<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BButton,BCard} from 'bootstrap-vue'



export default {
  components: {
    BCard,
    baseform
  },

  data() {
    return {
      loading :false,
      dataurl:"/skko",
      baseroute:"skko",
      title:"SKKO",
      fields : [
        { key: 'number', label: 'Code', type: 'input', rules:'required' },
        { key: 'vendor', label: 'Vendor', type: 'select2', rules:'required', dataurl:'vendor?filter[approval_status]=1', placeholder:'Pilih Vendor' },
        { key: 'date_start', label: 'Periode Awal', type: 'date', rules:'required' },
        { key: 'date_end', label: 'Periode Akhir', type: 'date', rules:'required' },
        { key: 'year', label: 'Tahun', type: 'input-number', rules:'required' },
        { key: 'nominal', label: 'Nominal', type: 'number', rules:'required' },
        { key: 'description', label: 'Keterangan', type: 'text' },
      ]
    }
  },
  // mounted(){
  //   if(this.$route.params.id != null)
  //   this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
  //     this.model = res.data
  //   })
  // },
}
</script>
